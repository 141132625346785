import React, { useMemo } from "react";
import { graphql } from "gatsby";
import Grid from "../components/grid";
import { defaultTagValues } from "../tags";

const Work = ({
  location,
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const defaultValue = useMemo(() => {
    if (location.state === null && location?.state?.key)
      return defaultTagValues;
    return { ...defaultTagValues, ...location?.state };
  }, [location]);

  return <Grid defaultValue={defaultValue} edges={edges} title="Work" />;
};

export default Work;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            tags
            tile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
