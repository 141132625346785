import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { useForm, useWatch } from "react-hook-form";

import Tag from "./tag";
import Layout from "./layout";
import SEO from "./seo";
import tagOptions from "../tags";

const IndexPage = ({ edges, title, defaultValue }) => {
  const [filteredEdges, setFilteredEdges] = useState(edges);
  const { register, reset, control } = useForm();
  const values = useWatch({ control });

  useEffect(() => {
    reset(defaultValue);
    window.history.replaceState(null, "");
  }, [defaultValue]);

  useEffect(() => {
    const filters = Object.entries(values).reduce(
      (acc, [key, value]) =>
        value && key !== "key" ? [...acc, key.toLowerCase()] : acc,
      []
    );

    setFilteredEdges(
      filters.length > 0 || !filters.every((filter) => !filter)
        ? edges.filter((edge) => {
            return edge.node.frontmatter.tags.some((tag) => {
              return filters.includes(tag);
            });
          })
        : edges
    );
  }, [values]);

  const projects = filteredEdges.map((edge) => {
    let tileImg = edge.node.frontmatter.tile?.childImageSharp.fluid;
    return (
      <StyledLink to={edge.node.frontmatter.path} key={edge.node.id}>
        <ImgContainer>{tileImg && <StyledImg fluid={tileImg} />}</ImgContainer>
        <ProjectTitle>{edge.node.frontmatter.title}</ProjectTitle>
      </StyledLink>
    );
  });

  return (
    <Layout>
      <SEO title={title} />
      <StyledH1>{title}</StyledH1>
      <Container>
        <FilterRow>
          {tagOptions.map((tag) => {
            return <Tag key={tag} {...{ register, tag }} />;
          })}
        </FilterRow>

        <ImageGrid>{projects}</ImageGrid>
      </Container>
    </Layout>
  );
};

export default IndexPage;

const ImgContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`;

const StyledImg = styled(Img)`
  && {
    position: absolute !important;
    top: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
    &::after {
      transition: 0.2s ease-in-out;
      opacity: 0;
      content: "";
      position: absolute;
      background-color: ${(props) => props.theme.blue};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    :hover {
      &::after {
        transition: 0.2s ease-in-out;
        opacity: 0.4;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3em;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const StyledH1 = styled.h1`
  margin: 2em auto 0.5em auto;
  text-align: center;
  ${({ theme: { blue } }) => `color: ${blue}`};
  font-weight: bold;
  font-size: 2em;
`;

const ProjectTitle = styled.p`
  margin: 0.5em;
  font-weight: bold;
  font-size: 0.85em;
  ${({ theme: { blue } }) => `color: ${blue}`};
`;
